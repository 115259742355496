<template>
  <Layout>
    <div class="wrapper">
      <div
        ref="lottie"
        class="building"
      />
      <div>TODO: 本页面正在快速设计&研发中.......</div>
    </div>
  </Layout>
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'TODO',
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    const animation = lottie.loadAnimation({
      container: this.$refs.lottie, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: '/animation/building.json' // the path to the animation json
    })
    animation.onComplete = () => {
      animation.playSegments([24, 50])
      animation.setSpeed(0.5)
    }
  }
}
</script>

<style scoped lang="less">
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    .building{
      width: 512px;
    }
  }
</style>
